import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { Seo } from "../../components/seo";
import { EntriesQueryInterface } from "../../types/entries";
import { LayoutDefault } from "../../layouts/default";
import { EntryBlocks } from "../../components/entry-blocks";
import { ViewportSize } from "../../components/grid/content-blocks/container/types";
import { BreadCrumbs } from "../../components/breadcrumbs";

const WorkContainer = styled.div`
  padding: 0 1rem;
  margin: auto;
  max-width: var(--content-width-max);

  @media (min-width: ${ViewportSize.TABLET}px) {
    padding: 0;
    width: var(--content-width-tablet);
  }

  @media (min-width: ${ViewportSize.DESKTOP}px) {
    width: var(--content-width-desktop);
  }
`;

export const ProjectPage = (props: EntriesQueryInterface) => {
  const { data } = props;
  const {
    craft: { entries },
  } = data;
  const title = "Work";

  // const schema: SchemaInterface = {
  //   type: 'BlogPosting',
  //   // dateModified: dateUpdated,
  //   // datePublished: postDate,
  //   name: title,
  //   headline: title,
  //   author: {
  //     firstName: 'Tanveer',
  //     lastName: 'Karim',
  //   },
  //   uri: '/work',
  // }

  return (
    <LayoutDefault>
      <Seo title={title} />
      <WorkContainer>
        <BreadCrumbs />
        <h1>{title}</h1>
        <EntryBlocks entries={entries} />
      </WorkContainer>
    </LayoutDefault>
  );
};

export const query = graphql`
  query ProjectsPageQuery {
    craft {
      entries(section: "projects") {
        ...entryProjectShowcase
      }
    }
  }
`;

export default ProjectPage;
